import React from "react"
import { FormPage } from "../components/formPage/FormPage"

import Layout from "../layout"
import SEO from "../components/seo"

const Formular = ({ location }) => {
  return (
    <Layout>
      <SEO title="Formular" klarna />
      <FormPage
        orderState={location.state?.orderState}
        orderType={location.state?.orderType}
        car={location.state?.car}
        date={location.state?.date}
      />
    </Layout>
  )
}

export default Formular
